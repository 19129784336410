@import '/apps/wt/webtools/sparta_build_server/tmp/EE81542B/artifacts/components/utilities/global/sparta-style-utility/4.9.4/scss/_sparta-project.scss';
$moduleNameSpace: dynamic-name-space;




$connections-light: $cnx-light-family, $cnx-font-fallbacks;

[data-sparta-container] .seo-overview-content-module {

  .tiles-container {
    margin-bottom: $spacing-10;
    @media #{$small-only} {
      padding-left: $spacing-10;
      padding-right: $spacing-10;
    }
    @media #{$large-up} {
      margin-bottom: 35px;
    }
    >div {
      @media #{$medium-up} {
        display: flex;
        flex-direction: row;
        justify-content: center;
      }
    }
    h2 {
      text-align: center;
      color: $color-regal-one;
      font-family: $connections-light;
      font-size: 30px;
      line-height: .9;
      padding: $spacing-20 0 0;
      margin-bottom: 0;
      @media #{$medium-only} {
        padding-top: 25px;
      }
      @media #{$large-up} {
        padding-top: 37px;
      }
    }
    p {
      padding: 0 $spacing-10 $spacing-2 $spacing-10;
      line-height: 1.8;
      @media #{$medium-only} {
        padding: $spacing-20 15px 5px;
      }
      @media #{$large-up} {
        padding: $spacing-20 30px 15px;
      }
    }
    .column {
      background-color: $color-gray-s05;
      margin-bottom: 30px;

      @media #{$medium-up} {
        margin-top: $spacing-20;
      }
    }
    .left {
      @media #{$medium-up} {
        margin-right: $spacing-20;
      }
    }
    ul {
      padding: $spacing-20 $spacing-10 5px $spacing-10;
    }
  }
  .bottom-tile {
    @media #{$large-up} {
      width: 85% !important;
    }
  }
  $image-height-small: 300px;
  $image-height-medium: 386.484375px;
  $image-height-large: 450px;
  .picture-box {
    margin-bottom: 30px;
    height: $image-height-small;
    @media #{$medium-only} {
      height: $image-height-medium;
    }
    @media #{$large-up} {
      height: $image-height-large;
    }
    picture {
      height: $image-height-small;
      &>img {
        height: $image-height-small;
        @media #{$medium-only} {
          height: $image-height-medium;
        }
        @media #{$large-up} {
          height: $image-height-large;
        }
      }
    }
  }
}
